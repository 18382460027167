import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule, Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ProjectsComponent } from './projects/projects.component';
import { SiteBlogComponent } from './site-blog/site-blog.component';
import { ContactComponent } from './contact/contact.component';
import { MmBlogComponent } from './mm-blog/mm-blog.component';
import { MmBlogTwoComponent } from './mm-blog-two/mm-blog-two.component';
import { MmThreeComponent } from './mm-three/mm-three.component';
import { MediaMondayComponent } from './media-monday/media-monday.component';

import * as fromArticles from './state/reducer/reducer';
import { environment } from '../environments/environment';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'about', component: AboutComponent },
  { path: 'blogs', component: BlogPageComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'site-blog', component: SiteBlogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'media-monday', component: MediaMondayComponent },
  { path: 'media-monday2', component: MmBlogTwoComponent },
  { path: 'media-monday3', component: MmThreeComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes), 
    StoreModule.forRoot(fromArticles.reducer)
    // StoreDevtoolsModule.instrument({
    //   name: 'Girl With Nerd',
    //   maxAge: 25
    // })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
