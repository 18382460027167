import { createReducer, on } from '@ngrx/store';
import * as ArticleActions from '../actions/actions';

import { Article } from "../../models/articles";

export const articlesKey = 'articles';

export interface ArticleState {
  articles: Article[];
  mediaMondayPosts:[];
  gwnBlogs: [];
  mediumBlogs: [];
}
 
export const initialState = {
  articles: [],
  mediaMondayPosts:[],
  gwnBlogs: [],
  mediumBlogs: []
}
 
const articleReducer = createReducer(
  initialState,
  on(ArticleActions.UpdateBlogs, (state, { blogs }) => ({
    ...state,
    articles: blogs
  }))
);
 
export function reducer(state: ArticleState, action) {
  return articleReducer(state, action);
}