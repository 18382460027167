import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ArticlesComponent } from './articles/articles.component';
import { BlogComponent } from './blog/blog.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ProjectsComponent } from './projects/projects.component';
import { SiteBlogComponent } from './site-blog/site-blog.component';
import { HeaderDarkComponent } from './header-dark/header-dark.component';
import { ContactComponent } from './contact/contact.component';
import { MmBlogComponent } from './mm-blog/mm-blog.component';
import { MmBlogTwoComponent } from './mm-blog-two/mm-blog-two.component';
import { MmThreeComponent } from './mm-three/mm-three.component';
import { MediaMondayComponent } from './media-monday/media-monday.component';
import { FooterComponent } from './footer/footer.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as fromArticles from './state/reducer/reducer';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SocialMediaComponent,
    CarouselComponent,
    ArticlesComponent,
    BlogComponent,
    AboutComponent,
    HomeComponent,
    BlogPageComponent,
    ProjectsComponent,
    SiteBlogComponent,
    HeaderDarkComponent,
    ContactComponent,
    MmBlogComponent,
    MmBlogTwoComponent,
    MmThreeComponent,
    MediaMondayComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    StoreModule.forFeature(fromArticles.articlesKey, fromArticles.reducer),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
