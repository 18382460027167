import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})

export class CarouselComponent implements OnInit {
  slideTimer = interval(5000)
  slideList = ['../../assets/money-money.jpg', "../../assets/coffee.jpg", "../../assets/los-angeles.jpg"]
  subscription: Subscription;
  intervalId: any;
  workhard = '../../assets/workhard.jpeg';
  mountain = '../../assets/mountain-top.jpeg';
  breaks = '../../assets/take-breaks.jpeg';

  constructor() { 
  }

  ngOnInit() {
    // this.intervalId = setInterval(() => {
    //   this.changeSlide()
    // }, 5000);
  }

  changeSlide() {
    const slide1 = this.slideList[0]
    const slide2 = this.slideList[1]
    const slide3 = this.slideList[2]

    this.slideList[0] = slide2
    this.slideList[1] = slide3
    this.slideList[2] = slide1
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

}
