import { Component, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

import { Article, ArticleType, Content } from "../models/articles";

import { ArticleState } from '../state/reducer/reducer';
import * as ArticleSelectors from '../state/selectors/selectors';
import * as ArticleActions from '../state/actions/actions';

@Component({
  selector: 'app-site-blog',
  templateUrl: './site-blog.component.html',
  styleUrls: ['./site-blog.component.css']
})
export class SiteBlogComponent implements OnInit {

  showSiteBlog1 = false;
  showSiteBlog2 = true;
  blogNumber = 0;
  currentArticle: Article;
  currentArticleContent: Content[];

  private _getBlogs$ = this._store.pipe(select(ArticleSelectors.getBlogs))

  constructor(
    private _route: ActivatedRoute, 
    private _firestore: AngularFirestore,
    private _store: Store<ArticleState>
  ) { }

  ngOnInit() {
    this.blogNumber = parseInt(this._route.snapshot.queryParamMap.get('blog'));
    this._getBlogs$.subscribe(blogPosts => {
      if (blogPosts.length <= 0) {
        const articleData = this._firestore.collection('articles').valueChanges();
        articleData.subscribe(blogs => {
          var siteBlogs = blogs as Article[]
          siteBlogs = siteBlogs.filter(blog => blog.type === ArticleType.SiteBlog.toString())

          var sortedSiteBlogs = this.sortBlogs(siteBlogs)
          this.currentArticle = sortedSiteBlogs[this.blogNumber - 1];
          this.currentArticleContent = sortedSiteBlogs[this.blogNumber - 1].content;
          this._store.dispatch(ArticleActions.UpdateBlogs({ blogs: blogs as Article[] }));
        });
      } else {
        var siteBlogs = blogPosts as Article[]
        siteBlogs = siteBlogs.filter(blog => blog.type === ArticleType.SiteBlog.toString())

        var sortedSiteBlogs = this.sortBlogs(siteBlogs)
        this.currentArticle = sortedSiteBlogs[this.blogNumber - 1];
        this.currentArticleContent = sortedSiteBlogs[this.blogNumber - 1].content;
      }
    })
  }

  sortBlogs(blogs: Article[]) {
    const sortedBlogs = blogs.slice()
    sortedBlogs.sort((a, b) => {
      const firstDate = new Date(a.date)
      const secondDate = new Date(b.date)
      if (firstDate.getTime() < secondDate.getTime()) {
        return -1;
      } else if (firstDate.getTime() > secondDate.getTime()) {
        return 1;
      } else {
        return 0;
      }
    })
    return sortedBlogs;
  }
}
