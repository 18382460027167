import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';

import { Article } from "../models/articles";

import { ArticleState } from '../state/reducer/reducer';
import * as ArticleActions from '../state/actions/actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private _firestore: AngularFirestore,
    private _store: Store<ArticleState>) { }

  ngOnInit() {
    const articleData = this._firestore.collection('articles').valueChanges();
    articleData.subscribe(blogs => {
      const blogPosts = blogs as Article[]
      this._store.dispatch(ArticleActions.UpdateBlogs({ blogs: blogPosts }));
    });
  }
}
