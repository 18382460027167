import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mm-three',
  templateUrl: './mm-three.component.html',
  styleUrls: ['./mm-three.component.css']
})
export class MmThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
