import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  public cali = '../../assets/los-angeles.jpg'
  public cali2 = '../../assets/cali2.jpg'
  public coffee = '../../assets/coffee.jpg'
  public business = '../../assets/business.jpg'
  public money = '../../assets/money-money.jpg'
  public purple = '../../assets/purple.jpg'
  public instagram = '../../assets/instagram.jpg'
  public dreams = '../../assets/dreams.jpg'
  public neverStop = '../../assets/never-stop.jpg'
  public womenInTech = '../../assets/women_in_tech.jpg'
  public introvert = '../../assets/introvert2.jpg'
  public quarantine = "../../assets/quarantine.jpg"
  public yes = "../../assets/yes.jpg"
  public blackwoman = "../../assets/blackwoman.jpg"
  public showText = true
  public dontShow = false

  constructor() { }

  ngOnInit() {
  }

}
