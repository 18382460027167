export enum ArticleType {
    MediaMonday = "media-monday",
    SiteBlog = "site-blog",
    MediumBlog = "medium-blog"
}

export interface Article {
    date: string;
    imgUrl: string;
    link?: string;
    snippet: string;
    title: string;
    type: ArticleType;
    text?: string;
    content?: Content[];
    index?: number;
}

export interface Content {
    type: string;
    text: string;
}
