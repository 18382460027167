import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onSubmit() {
    const subject = (<HTMLInputElement>document.getElementById("subject")).value;
    const message = (<HTMLInputElement>document.getElementById("body")).value;
    window.open(`mailto:girlwithnerd.com@gmail.com?subject=${subject}&body=${message}`, '_blank');
  }

}
