// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAMVoptTXfOtJ-9hP95YAKfSW2CEJe9pZ8",
    authDomain: "girl-with-nerd.firebaseapp.com",
    databaseURL: "https://girl-with-nerd.firebaseio.com",
    projectId: "girl-with-nerd",
    storageBucket: "girl-with-nerd.appspot.com",
    messagingSenderId: "114447087404",
    appId: "1:114447087404:web:c2b345e221cb53d8cc1e89",
    measurementId: "G-BKKF63VD8T"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
