import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { Article, ArticleType } from "../models/articles";
import { ArticleState } from '../state/reducer/reducer';
import * as ArticleActions from '../state/actions/actions';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.css']
})
export class BlogPageComponent implements OnInit {
  items: Observable<any[]>;
  items2: Observable<any[]>;
  blogs: Article[]
  mediaMondayBlogs: Article[]
  showMediaMonday = false;
  showBlogs = true;
  mmNumber = 1;

  constructor(private _firestore: AngularFirestore, private _store: Store<ArticleState>) { }

  ngOnInit() {
    const articles = this._firestore.collection('articles').valueChanges();
    articles.subscribe(blogPosts => {
      const blogTypes = this.getBlogsByType(blogPosts as Article[]);
      const siteBlogs = blogTypes[0]
      const mediaMondayPosts = blogTypes[1]

      const sortedBlogPosts = this.sortBlogs(siteBlogs as Article[]);
      const sortedMMPosts = this.sortBlogs(mediaMondayPosts as Article[])

      this.blogs = sortedBlogPosts;
      this.mediaMondayBlogs = sortedMMPosts;
      this._store.dispatch(ArticleActions.UpdateBlogs({ blogs: blogPosts as Article[]}));
    })
  }

  getBlogsByType(blogs: Article[]) {
    const siteBlog: Article[] = []
    const mediaMondayPosts: Article[] = []

    blogs.forEach(blog => {
      if (blog.type == ArticleType.MediaMonday) {
        mediaMondayPosts.push(blog)
      } else {
        siteBlog.push(blog)
      }
    })

    return [siteBlog, mediaMondayPosts]
  }

  sortBlogs(blogs: Article[]) {
    const sortedBlogs = blogs.slice()
    sortedBlogs.sort((a, b) => {
      const firstDate = new Date(a.date)
      const secondDate = new Date(b.date)
      if (firstDate.getTime() < secondDate.getTime()) {
        return 1;
      } else if (firstDate.getTime() > secondDate.getTime()) {
        return -1;
      } else {
        return 0;
      }
    })
    return sortedBlogs;
  }

  showBlogPosts() {
    this.showMediaMonday = false;
    this.showBlogs = true;
  }

  showMMPosts() {
    this.showMediaMonday = true;
    this.showBlogs = false;
  }
}
