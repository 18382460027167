import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() showSubtitle = false;
  @Input() text = "Girl With Nerd";
  @Input() showMediaMonday = false;
  @Input() showMediaMonday2 = false;
  @Input() showMediaMonday3 = false;
  @Input() showBlogs = false;
  @Input() empty = false;
  @Input() showBlog1 = false;
  @Input() showBlog2 = false;
  @Input() showNav = false;
  showJumbotron = true;

  constructor() { }

  ngOnInit() {
  }

}
