import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

import { Article, ArticleType, Content } from "../models/articles";

import { ArticleState } from '../state/reducer/reducer';
import * as ArticleSelectors from '../state/selectors/selectors';
import * as ArticleActions from '../state/actions/actions';

@Component({
  selector: 'app-media-monday',
  templateUrl: './media-monday.component.html',
  styleUrls: ['./media-monday.component.css']
})
export class MediaMondayComponent implements OnInit {

  mmNumber = 1;
  currentArticle: Article;
  currentArticleContent: Content[];

  private _getBlogs$ = this._store.pipe(select(ArticleSelectors.getBlogs))

  constructor(private _store: Store<ArticleState>, private _route: ActivatedRoute, 
    private _firestore: AngularFirestore) { }

  ngOnInit() {
    this.mmNumber = parseInt(this._route.snapshot.queryParamMap.get('blog'));
    this._getBlogs$.subscribe(blogPosts => {
      if (blogPosts.length <= 0) {
        const articleData = this._firestore.collection('articles').valueChanges();
        articleData.subscribe(blogs => {
          var mmBlogs = blogs as Article[]
          mmBlogs = mmBlogs.filter(blog => blog.type === ArticleType.MediaMonday.toString())

          var sortedSiteBlogs = this.sortBlogs(mmBlogs)
          this.currentArticle = sortedSiteBlogs[this.mmNumber - 1];
          this.currentArticleContent = sortedSiteBlogs[this.mmNumber - 1].content;
          this._store.dispatch(ArticleActions.UpdateBlogs({ blogs: blogs as Article[] }));
        });
      } else {
        var mmBlogs = blogPosts as Article[]
        mmBlogs = mmBlogs.filter(blog => blog.type === ArticleType.MediaMonday.toString())

        var sortedSiteBlogs = this.sortBlogs(mmBlogs)
        this.currentArticle = sortedSiteBlogs[this.mmNumber - 1];
        this.currentArticleContent = sortedSiteBlogs[this.mmNumber - 1].content;
      }
    })
  }

  sortBlogs(blogs: Article[]) {
    const sortedBlogs = blogs.slice()
    sortedBlogs.sort((a, b) => {
      const firstDate = new Date(a.date)
      const secondDate = new Date(b.date)
      if (firstDate.getTime() < secondDate.getTime()) {
        return -1;
      } else if (firstDate.getTime() > secondDate.getTime()) {
        return 1;
      } else {
        return 0;
      }
    })
    return sortedBlogs;
  }

}
