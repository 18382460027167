import { createAction, props } from '@ngrx/store';
import { Article} from "../../models/articles";

export enum ArticleActionsEvent {
    GetBlogs = '[Articles] Get Articles'
}

export const UpdateBlogs = createAction(
    '[Articles] Update Articles',
    props<{blogs: Article[]}>()
)
